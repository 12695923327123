import React, {useEffect, useState} from 'react';
import LinearGradient from '@basicComponents/linear-gradient';
import theme from '@style';
import {StyleSheet, View, Image} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import LazyImage from '@/components/basic/image';
import {useTranslation} from 'react-i18next';
import Text from '@basicComponents/text';
import Tag from '@/components/basic/tag';
import {postSpinConfig} from '@/common-pages/luckyspin/luckyspin.service';
import {useLuckySpinModal} from '@/common-pages/luckyspin/luckyspin.hooks';
import globalStore from '@/services/global.state';

const {flex, padding, font, margin, background} = theme;

export interface MeRowMenuProps {
  onRecharge?: () => void;
  onWithdraw?: () => void;
  onTransfer?: () => void;
  login: boolean;
  hasFree: boolean;
  refresh: () => void;
}

const MeRowMenu: React.FC<MeRowMenuProps> = ({
  onRecharge,
  onWithdraw,
  onTransfer,
  login,
  hasFree,
  refresh,
}) => {
  const {calcActualSize} = useScreenSize();
  const styles = StyleSheet.create({
    bottomNav: {
      height: calcActualSize(80),
    },
    bottomNavItem: {
      width: calcActualSize(87.85),
    },
    upIcon: {
      width: calcActualSize(40),
      height: calcActualSize(40),
    },
    vipNavsItemTag: {
      position: 'absolute',
      top: 5,
      right: 5,
    },
  });
  const {i18n} = useTranslation();
  const [innerHasFree, setInnerHasFree] = useState<boolean>(hasFree);
  useEffect(() => {
    setInnerHasFree(hasFree);
  }, [hasFree]);
  const getNavTag = (_hasFree: boolean) =>
    _hasFree ? <Tag style={styles.vipNavsItemTag} content={'!'} /> : null;

  const onRefreshSpinConfig = () => {
    if (login) {
      postSpinConfig(true).then(data => {
        setInnerHasFree(!!data?.myFree);
      });
      return refresh;
    }
  };
  const {renderModal: renderSpin, show: spinShow} = useLuckySpinModal({
    onNotice: onRefreshSpinConfig,
  });
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      globalStore.asyncGetItem('luckyspinBackground').then(luckyspinBg => {
        if (luckyspinBg == null || luckyspinBg === '') {
          postSpinConfig(!!token).then(data => {
            Image.prefetch(data?.image);
            if (data?.image != null && data.image !== '') {
              globalStore.asyncSetItem('luckyspinBackground', data?.image);
            }
          });
        } else {
          Image.prefetch(luckyspinBg);
        }
      });
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return (
    <LinearGradient
      start={{x: 0, y: 0}}
      end={{x: 0, y: 1}}
      colors={['#fff', '#fff0']}
      style={[padding.lrl, background.lightGrey]}>
      <View style={[flex.row, styles.bottomNav, flex.around]}>
        <NativeTouchableOpacity
          style={[flex.center, styles.bottomNavItem]}
          onPress={onRecharge}>
          <View style={[flex.center]}>
            <View style={[styles.upIcon, flex.center]}>
              <LazyImage
                occupancy={'transparent'}
                imageUrl={require('@assets/icons/wallet/recharge.webp')}
                width={calcActualSize(28)}
                height={calcActualSize(28)}
              />
            </View>
            <Text style={[font.fs, font.second, margin.topxxs]} calc>
              {i18n.t('me.bottom.recharge')}
            </Text>
          </View>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          style={[flex.center, styles.bottomNavItem]}
          onPress={onWithdraw}>
          <View style={[flex.center]}>
            <View style={[styles.upIcon, flex.center]}>
              <LazyImage
                occupancy={'transparent'}
                imageUrl={require('@assets/icons/me/withdraw.webp')}
                width={calcActualSize(28)}
                height={calcActualSize(28)}
              />
            </View>
            <Text style={[font.fs, font.second, margin.topxxs]} calc>
              {i18n.t('me.bottom.withdraw')}
            </Text>
          </View>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          style={[flex.center, styles.bottomNavItem]}
          onPress={onTransfer}>
          <View style={[flex.center]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={require('@assets/icons/me/transfer-gif.gif')}
              width={calcActualSize(40)}
              height={calcActualSize(40)}
            />
            <Text style={[font.fs, font.second, margin.topxxs]} calc>
              {i18n.t('me.bottom.transfer')}
            </Text>
          </View>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          activeOpacity={0.8}
          onPress={spinShow}
          style={[flex.center, styles.bottomNavItem]}>
          <View style={[flex.center]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={require('@assets/gif/luckyspin.gif')}
              width={calcActualSize(40)}
              height={calcActualSize(40)}
            />
            <Text style={[font.fs, font.second, margin.topxxs]}>
              {i18n.t('home.label.lucky-spin')}
            </Text>
            {getNavTag(innerHasFree)}
          </View>
        </NativeTouchableOpacity>
      </View>
      {renderSpin}
    </LinearGradient>
  );
};

export default MeRowMenu;
